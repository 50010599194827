import { createFiqlFilter, parseDashboardResponse } from "../../lib/helper";

import Constants from "../../utils/CommonConstants";
import { instance } from "../../lib/api";
import getKeySearchPromises from "../../utils/getKeySearchPromises";

export const API_FAIL = "API_FAIL";
export const GET_LIST = "GET_LIST";
export const GET_COLLECTION = "GET_COLLECTION";
export const DASHBOARD_STATS = "DASHBOARD_STATS";
export const DASHBOARD_RESET = "DASHBOARD_RESET";
export const DASHBOARD_STATS_RESET = "DASHBOARD_STATS_RESET";
export const TEAM_SELF_CLICKED = "TEAM_SELF_CLICKED";
export const DOWNLOAD_CSV_SALES_CONN = "DOWNLOAD_CSV_SALES_CONN";
export const SET_TOTAL_COUNT = "SET_TOTAL_COUNT";
export const SET_MORTGAGE_CITIES = "SET_MORTGAGE_CITIES";

export const getDashboard = (
  isSelfClicked,
  agentId = null,
  oldFilters,
  keys = false,
  forDashboard = true,
  isSalesFlag = false
) => {
  const dashboardEntities = Constants.dashboardEntities;
  const homePaymentEntities = Constants.homePaymentEntities;
  const homeTermsheetEntities = Constants.homeTermsheetEntities;
  const salesConnectEntities = Constants.salesConnectEntities;
  const mortgageEntities = Constants.mortgageEntities;
  const selectorArr = [];
  const { paging = { start: 0, rows: 0 } } = oldFilters || {};
  const _keys = keys || Object.keys(dashboardEntities);
  _keys.forEach((key) => {
    const keyFilter =
      dashboardEntities[key] ||
      homePaymentEntities[key] ||
      homeTermsheetEntities[key] ||
      salesConnectEntities[key] ||
      mortgageEntities[key];

    const filters = {};

    if (keyFilter.addAsFilter) {
      const fiqlFilter = createFiqlFilter(keyFilter, agentId, oldFilters, filters);
      if (fiqlFilter.and.length > 0) {
        filters.filters = fiqlFilter;
      }
    }
    filters.key = keyFilter.key;
    if (agentId && !keyFilter.useDiscountApprovingUserId) {
      filters.agentId = agentId;
    }

    filters.team =
      key === "disount-approval-pending-on-me" ||
      key === "discount-reviews-pending-on-me"
        ? false
        : !isSelfClicked;
    filters.paging = paging;
    if (keyFilter.caseSubTypeId) {
      filters.caseSubTypeId = keyFilter.caseSubTypeId;
    }
    if (keyFilter.sortKey) {
      filters.sort = keyFilter.sortKey;
    }
    selectorArr.push(filters);
  });
  const strSelector = JSON.stringify(selectorArr);

  let promise;
  let url = "/mystique/v1/soc/key-search?salesOpportunitySelectorsArray=";
  let checkKey = "";
  if (Array.isArray(_keys) && _keys.length) {
    checkKey = _keys[0];
  }
  if (homePaymentEntities[checkKey]) {
    url =
      "/mystique/rcm/v1/housing-developer-payments/payments/key-search?paymentSelectorsArray=";
  } else if (homeTermsheetEntities[checkKey]) {
    url =
      "/mystique/rcm/v1/housing-developer-termsheet/termsheet/key-search?termsheetSelectorsArray=";
  }
  if (salesConnectEntities[checkKey]) {
    url = `/mystique/v1/soc/key-search?isSalesConnect=${isSalesFlag}&salesOpportunitySelectorsArray=`;
  }
  if (mortgageEntities[checkKey]) {
    let size = 30;
    let p = paging.start / size + 1;
    const { selectedCityIds = [], selectedPartnerType } = oldFilters || {};
    url = `/sapna/v0/get-all-mortgage-opportunities`;
    const params = {
      p,
      size,
      ...(mortgageEntities[checkKey]?.name && {
        statuses: mortgageEntities[checkKey].name,
      }),
      ...(selectedCityIds && { cityIds: selectedCityIds.join(",") }),
      ...(selectedPartnerType && { clientTypeIds: selectedPartnerType }),
      ...(mortgageEntities[checkKey]?.approvalType && {
        approvalType: mortgageEntities[checkKey].approvalType,
      }),
    };
    promise = instance({
      url,
      method: "get",
      params: params,
    });
  } else {
    promise = getKeySearchPromises(url, strSelector);
  }

  return (dispatch) => {
    return promise
      .then((response) => {
        if (!forDashboard) {
          //  listing page data
          dispatch({
            type: GET_LIST,
            data: mortgageEntities[checkKey]
              ? {
                  hasNextPage: response.data.data.hasNextPage,
                  results: response.data.data.mortgageOpportunities,
                }
              : response && response[0],
          });
        } else {
          //  dashboard data
          dispatch({
            type: DASHBOARD_STATS,
            payload: {
              filters: oldFilters,
              dashboard: parseDashboardResponse(response, keys),
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const resetDashboard = () => {
  return (dispatch) => {
    dispatch({
      type: DASHBOARD_RESET,
    });
  };
};

export const resetDashboardStats = () => {
  return (dispatch) => {
    dispatch({
      type: DASHBOARD_STATS_RESET,
    });
  };
};

export const showSelfData = (isSelfClicked) => {
  return (dispatch) => {
    dispatch({
      type: TEAM_SELF_CLICKED,
      isSelfClicked,
    });
  };
};

export const downnloadCSVsc = (
  isSelfClicked,
  agentId = null,
  oldFilters,
  keyName = null
) => {
  const salesConnectEntities = Constants.salesConnectEntities;
  const selectorArr = [];
  const { paging = { start: 0, rows: 0 } } = oldFilters || {};
  // eslint-disable-next-line no-param-reassign
  // const _keys = keyName || Object.keys(salesConnectEntities);

  const keyFilter = salesConnectEntities[keyName];
  const filters = {};

  if (keyFilter.addAsFilter) {
    const fiqlFilter = createFiqlFilter(keyFilter, agentId, oldFilters, filters);
    if (fiqlFilter.and.length > 0) {
      filters.filters = fiqlFilter;
    }
  }
  filters.key = keyFilter.key;
  if (agentId && !keyFilter.useDiscountApprovingUserId) {
    filters.agentId = agentId;
  }

  filters.team =
    keyName === "disount-approval-pending-on-me" ? false : !isSelfClicked;
  filters.paging = paging;
  if (keyFilter.caseSubTypeId) {
    filters.caseSubTypeId = keyFilter.caseSubTypeId;
  }
  if (keyFilter.sortKey) {
    filters.sort = keyFilter.sortKey;
  }
  selectorArr.push(filters);

  const strSelector = JSON.stringify(selectorArr);

  return (dispatch) => {
    const url = `/mystique/v1/soc/get/sales-connect-report.csv?salesOpportunitySelectorsArray=${strSelector}`;

    // eslint-disable-next-line no-undef
    window.open(url, "_self");
    dispatch({
      type: DOWNLOAD_CSV_SALES_CONN,
      msg: "Sales Connect Report is downloading...",
    });
  };
};

export const getSalesCollection = (fromDate, toDate) => {
  let startDate = new Date(fromDate).getTime();
  let endDate = new Date(toDate).getTime();
  let url = `/sapna/v1/opportunity/collected-amount-salesconnect?fromDate=${startDate}&toDate=${endDate}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: GET_COLLECTION,
          collectionAmt: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getMortgageCities = () => {
  return (dispatch) => {
    const url = "mortgage/api/v0/eligible-cities-name-id-mapping";
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: SET_MORTGAGE_CITIES,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getMortgageRegistrationCount = (
  status = null,
  selectedCityIds = null,
  selectedPartnerType
) => {
  let url = `sapna/v0/count-mortgage-opportunities`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
      params: {
        ...(status && { statuses: status }),
        ...(selectedCityIds && { cityIds: selectedCityIds.join(",") }),
        ...(selectedPartnerType && { clientTypeIds: selectedPartnerType }),
      },
      withCredentials: true,
    })
      .then((response) => {
        const { BankDetailsApprovalByRCM, KycApprovalByRCM } =
          response.data.data.approvalTypeWiseCount;
        let data = {
          ...KycApprovalByRCM.approvalStatusWiseCount,
          ...Object.fromEntries(
            Object.entries(BankDetailsApprovalByRCM.approvalStatusWiseCount).map(
              ([key, value]) => [`${key}_BU`, value]
            )
          ),
        };
        dispatch({
          type: SET_TOTAL_COUNT,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
