import { instance } from "../../lib/api";
import {
  getOpportunityTags,
  SET_OPPORTUNITY_TAGS_IN_ACCOUNT,
} from "../actions/opportunity.actions";
import Constants from "../../utils/CommonConstants";
import { getAccountDetailsFromHousing } from "../../redux/actions/myAccounts.actions";
import { dateFormat } from "../../utils/dateUtils";
import { aggregateToObject } from "../../utils/arrayUtils";
import ownerConstant from "../../utils/ownerConstants";
import EventEmitter from "../../utils/EventEmitter";
import { resolveObjectOfPromises } from "../../utils/resolvePromiseObject";
export const LSQ_MIGRATION_DETAILS = "LSQ_MIGRATION_DETAILS";
export const GOT_CONTACTS = "GOT_CONTACTS";
export const RESET_CONTACTS = "RESET_CONTACTS";
export const CONTACT_UPDATED = "CONTACT_UPDATED";
export const CONTACT_DELETED = "CONTACT_DELETED";
export const ACCOUNT_DETAILS = "ACCOUNT_DETAILS";
export const ACCOUNT_UPDATED = "ACCOUNT_UPDATED";
export const NEW_CONTACT_CREATED = "NEW_CONTACT_CREATED";
export const NEW_CONTACT_ERROR = "NEW_CONTACT_ERROR";
export const NEW_CONTACT_ERROR_RESET = "NEW_CONTACT_ERROR_RESET";
export const RESET_ACCOUNT_CHILD = "RESET_ACCOUNT_CHILD";
export const ACCOUNT_CHILD_DETAILS = "ACCOUNT_CHILD_DETAILS";
export const ACCOUNT_CHILD_DETAILS_IN_PROGRESS = "ACCOUNT_CHILD_DETAILS_IN_PROGRESS";
export const ACCOUNT_CHILD_DETAILS_ERROR = "ACCOUNT_CHILD_DETAILS_ERROR";
export const CHILD_ACCOUNT_CREATED = "CHILD_ACCOUNT_CREATED";
export const LEGAL_ENTITY_ATTRIBUTES = "LEGAL_ENTITY_ATTRIBUTES";
export const CREATED_TICKET_SUCCESSULLY = "CREATED_TICKET_SUCCESSULLY";
export const LEGAL_ENTITY_UPDATED = "LEGAL_ENTITY_UPDATED";
export const ACCOUNT_DETAILS_UPDATED = "ACCOUNT_DETAILS_UPDATED";
export const LIVE_LISTINGS_REFRESH = "LIVE_LISTINGS_REFRESH";
export const LIVE_LISTINGS_BUY = "LIVE_LISTINGS_BUY";
export const LIVE_LISTINGS_RENT = "LIVE_LISTINGS_RENT";
export const ALL_LIVE_LISTINGS = "ALL_LIVE_LISTINGS";
export const RESEND_POC_VERIFICATION_SUCCESS = "RESEND_POC_VERIFICATION_SUCCESS";
export const RESEND_POC_VERIFICATION_IN_PROGRESS =
  "RESEND_POC_VERIFICATION_IN_PROGRESS";
export const RESEND_POC_VERIFICATION_ERROR = "RESEND_POC_VERIFICATION_IN_ERROR";
export const GET_BOUGHT_PACKAGES = "GET_BOUGHT_PACKAGES";
export const BOUGHT_PACKAGES_REFRESH = "BOUGHT_PACKAGES_REFRESH";
export const NON_RESIDENTIAL_LISTINGS = "NON_RESIDENTIAL_LISTINGS";
export const OWNER_LISTING_PAYMENT_STATUS = "OWNER_LISTING_PAYMENT_STATUS";
export const OWNER_DND_STATUS = "OWNER_DND_STATUS";
export const ALL_AND_LIVE_PENDING_LISTINGS = "ALL_AND_LIVE_PENDING_LISTINGS";
export const OWNER_PACKAGE_CITIES = "OWNER_PACKAGE_CITIES";
const getOpportunities = (opportunities) => {
  const {
    id,
    name,
    typeId,
    accountId,
    statusId,
    ownerName,
    dealValue,
    collectedAmount,
    createdAt,
    updatedAt,
    renewalAlertTag = "Loading...",
  } = opportunities;

  return {
    id,
    name,
    typeId,
    accountId,
    statusId,
    ownerName,
    dealValue,
    pendingCollectionAmount: dealValue - collectedAmount,
    createdAt,
    updatedAt,
    renewalAlertTag,
  };
};
const addOpenAndClosedDealValues = (opportunityDetails) => {
  let totalDealValue = 0;
  opportunityDetails.forEach((opportunity) => {
    if (
      opportunity.dealValue &&
      (Constants.opportunityStageBucket.open.includes(opportunity.statusId) ||
        Constants.opportunityStageBucket.closed.includes(opportunity.statusId))
    ) {
      totalDealValue += opportunity.dealValue;
    }
  });
  return totalDealValue;
};
const parseAccountDetail = (accountDetail) => {
  const { opportunityDetails, ...data } = accountDetail;

  return {
    ...data,
    totalAccountValue: addOpenAndClosedDealValues(opportunityDetails),
    opportunities: opportunityDetails.map((o) => getOpportunities(o)),
  };
};

const parseOpportunityIds = (accountDetails) => {
  const { opportunities = [] } = accountDetails || {};
  return opportunities.map(({ id }) => id);
};

export const getSellerWalletDetails = (accountId) => {
  let url = `sapna/v1/seller-wallet-payments/${accountId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getLdcDetails = (accountId) => {
  let url = `sapna/v1/get-all-tan-threshold/${accountId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getLsqHistoryLogs = (accountId, setState) => {
  let url = `sapna/v1/account-update-history-lsq?accountId=${accountId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        setState({
          lsqHistoryLogsData: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const migrateAccountToLsq = (accountId, responseMessage) => {
  let url = "sapna/v1/migrate-account-lsq";
  return (dispatch) => {
    return instance({
      url,
      method: "post",
      data: {
        id: accountId,
        doMigrateAllData: true,
      },
    })
      .then((response) => {
        const status = response?.data?.data?.status ?? "";
        let snackBarType = "error";
        let message = `${responseMessage} Migration Unsuccessful`;

        switch (status.toUpperCase()) {
          case "SUCCESS":
            snackBarType = "success";
            message = `${responseMessage} Migrated Successfully`;
            break;
          case "ERROR":
            snackBarType = "error";
            message = `${responseMessage} Migration Unsuccessful`;
            break;
          case "ALREADY MIGRATED":
            snackBarType = "warning";
            message = `${responseMessage} Already Migrated`;
            break;
        }

        dispatch({
          type: Constants.SNACKBAR_NOTIFICATION,
          snackBarType: snackBarType,
          msg: message,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getLSQMigrationDetails = (accountId) => {
  let url = `sapna/v1/check-if-account-migrated?accountId=${accountId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: LSQ_MIGRATION_DETAILS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getAccountDetail = (accountId) => {
  const url = `/sapna/v3/account-page/${accountId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        //  account details
        const parseAccDetail = parseAccountDetail(response.data.data);
        dispatch({
          type: ACCOUNT_DETAILS,
          payload: {
            accountId,
            data: parseAccDetail,
          },
        });
        dispatch(getAccountDetailsFromHousing(parseAccDetail.housingProfileId));
        dispatch(
          getOpportunityTags(
            parseOpportunityIds(parseAccDetail),
            SET_OPPORTUNITY_TAGS_IN_ACCOUNT
          )
        );
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const updateAccount = (accountId, data) => {
  const url = `/sapna/v1/account/${accountId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "put",
      data,
    })
      .then((response) => {
        dispatch({
          type: ACCOUNT_UPDATED,
          payload: {
            data: response.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getChildAccountDetail = (accountId) => {
  const url = `/sapna/v2/account-legal-entity/account/${accountId}`;
  return (dispatch) => {
    dispatch({
      type: ACCOUNT_CHILD_DETAILS_IN_PROGRESS,
      payload: true,
    });
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        //  account child details
        dispatch({
          type: ACCOUNT_CHILD_DETAILS,
          payload: {
            accountId,
            data: response.data.data.map(({ addressDetails, ...child }) => {
              let billingAddress = {},
                shippingAddress = {},
                billingAddressString = "Not available",
                shippingAddressString = "Not available";
              addressDetails &&
                addressDetails.forEach((address) => {
                  if (address.type === "Billing") {
                    billingAddress = {
                      addressId: address.id,
                      address: address.address,
                      cityId: address.cityId,
                      pincode: address.pincode || "",
                    };
                    billingAddressString = address.address;
                  } else if (address.type === "Shipping") {
                    shippingAddress = {
                      addressId: address.id,
                      address: address.address,
                      cityId: address.cityId,
                      pincode: address.pincode || "",
                    };
                    shippingAddressString = address.address;
                  }
                });
              return {
                ...child,
                billingAddress,
                billingAddressString,
                shippingAddress,
                shippingAddressString,
              };
            }),
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: ACCOUNT_CHILD_DETAILS_ERROR,
          payload: Constants.getErrorMessage(err),
        });
      });
  };
};

export const createChildAccount = (data, accountId, refreshLegalEntities) => {
  const url = "/sapna/v1/account-legal-entity";
  const method = "post";
  return (dispatch) => {
    return instance({
      url,
      method,
      data: { ...data, accountId },
    })
      .then((response) => {
        //  account details
        refreshLegalEntities && refreshLegalEntities();
        dispatch({
          type: CHILD_ACCOUNT_CREATED,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const updateLegalEntity = (id, data, refreshLegalEntities) => {
  const url = `/sapna/v1/account-legal-entity/${id}`;
  const method = "put";
  return (dispatch) => {
    return instance({
      url,
      method,
      data,
    })
      .then(() => {
        refreshLegalEntities && refreshLegalEntities();
        dispatch({
          type: LEGAL_ENTITY_UPDATED,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const updateAccountCityAndUUID = (accountId, data) => {
  let url = `/sapna/v2/account/${accountId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "PUT",
      data,
    })
      .then(() => {
        dispatch({
          type: ACCOUNT_DETAILS_UPDATED,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const updateLegalEntityAddress = (id, data, refreshLegalEntities) => {
  let url = id ? `/sapna/v1/address/${id}` : `/sapna/v1/address`;
  let method = id ? "put" : "post";
  return (dispatch) => {
    return instance({
      url,
      method,
      data,
    })
      .then(() => {
        refreshLegalEntities && refreshLegalEntities();
        dispatch({
          type: LEGAL_ENTITY_UPDATED,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        return Promise.reject(Constants.API_FAIL);
      });
  };
};
export const resetChildDetail = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_ACCOUNT_CHILD,
    });
  };
};

export const getAccountContacts = (accountId) => {
  const url = `/sapna/v1/contact?filters=active==true;accountId==${accountId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: GOT_CONTACTS,
          payload: {
            accountId,
            data: response.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const resetContactError = () => {
  return (dispatch) => {
    dispatch({
      type: NEW_CONTACT_ERROR_RESET,
    });
  };
};

export const createContact = (
  { name, email, phone, accountId, opportunityId, contactTypeId },
  pathname = ""
) => {
  const url = "/sapna/v1/contact";
  return (dispatch) => {
    return instance({
      url,
      method: "post",
      data: { name, email, phone, accountId, opportunityId, contactTypeId },
    })
      .then((response) => {
        dispatch({
          type: NEW_CONTACT_CREATED,
          payload: {
            accountId,
            data: response.data.data,
          },
        });
        if (pathname.includes("-opportunity")) {
          EventEmitter.emit("telesales-opportunity-data-updated");
        }
      })
      .catch((err) => {
        if (err.response) {
          const { status } = err.response;
          if (status !== 400 && status !== 401) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: Constants.getErrorMessage(err),
            });
            return;
          }
        }
        dispatch({
          type: NEW_CONTACT_ERROR,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const updateContact = (
  { contactId, name, email, phone, contactTypeId },
  pathname = ""
) => {
  const url = `/sapna/v1/contact/${contactId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "put",
      data: { name, email, phone, contactTypeId },
    })
      .then((response) => {
        dispatch({
          type: CONTACT_UPDATED,
          payload: {
            data: response.data.data,
          },
        });
        if (pathname.includes("-opportunity")) {
          EventEmitter.emit("telesales-opportunity-data-updated");
        }
      })
      .catch((err) => {
        if (err.response) {
          const { status } = err.response;
          if (status !== 400 && status !== 401) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: Constants.getErrorMessage(err),
            });
            return;
          }
        }
        dispatch({
          type: NEW_CONTACT_ERROR,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const deleteContact = (contactId, pathname) => {
  const url = `/sapna/v1/contact/${contactId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "delete",
    })
      .then(() => {
        dispatch({
          type: CONTACT_DELETED,
        });
        if (pathname.includes("-opportunity")) {
          EventEmitter.emit("telesales-opportunity-data-updated");
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const resetContacts = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_CONTACTS,
    });
  };
};

export const getLegalEntityAttributes = () => {
  const url = "/makaanTickets/api/v1/ticket/master/category/6/attributes";
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const legalEntityAttributes = response.data.data;
        dispatch({
          type: LEGAL_ENTITY_ATTRIBUTES,
          legalEntityAttributes,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
const parseAllLiveListingsResponse = (
  liveListings,
  listingType,
  listingCategory = "RESIDENTIAL"
) =>
  liveListings.map((listing) => {
    const {
      inventory_configs: inventoryConfigs = [],
      formatted_price: formattedPrice,
    } = listing;
    return {
      ...listing,
      formatted_price:
        formattedPrice ||
        (inventoryConfigs[0] && inventoryConfigs[0].formatted_price),
      listingType,
      listingCategory,
    };
  });

const getLeadsCountForService = ({ service, listings, accountUUID }) => {
  if (!listings.length) {
    return Promise.resolve({ data: { data: { details: [] } } });
  }
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 6);
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const listingIds = (listings || []).map((res) => res.id);
  const url = `/sapna/v1/housing/helper/24?services=${service}&property_ids=${listingIds.join()}&profile_uuid=${accountUUID}&start_date=${dateFormat(
    startDate,
    true
  )}&end_date=${dateFormat(today, true)}`;
  return instance({
    url,
    method: "get",
  });
};

const getLeadsCountForListings = ({
  buyListings,
  rentListings,
  pgListings,
  accountUUID,
}) => {
  return (dispatch) => {
    return Promise.all([
      getLeadsCountForService({
        service: "rent",
        listings: rentListings,
        accountUUID,
      }),
      getLeadsCountForService({
        service: "buy",
        listings: buyListings,
        accountUUID,
      }),
    ])
      .then((response) => {
        const data = response.map(
          (res) => (((res || {}).data || {}).data || {}).details
        );

        const [rentLeads, buyLeads] = data || [];
        if (rentLeads && rentLeads.length) {
          const rentLeadsAggregatedByPropertyId = aggregateToObject(
            "property_id",
            rentLeads
          );

          (rentListings || []).forEach((res) => {
            if (rentLeadsAggregatedByPropertyId[res.id]) {
              res["leads_data"] = [...rentLeadsAggregatedByPropertyId[res.id]];
            }
          });
        }
        if (buyLeads && buyLeads.length) {
          const buyLeadsAggregatedByPropertyId = aggregateToObject(
            "property_id",
            buyLeads
          );

          (buyListings || []).forEach((res) => {
            if (buyLeadsAggregatedByPropertyId[res.id]) {
              res["leads_data"] = [...buyLeadsAggregatedByPropertyId[res.id]];
            }
          });
        }
      })
      .finally(() => {
        dispatch({
          type: ALL_LIVE_LISTINGS,
          payload: {
            buyListings: parseAllLiveListingsResponse(buyListings, "BUY"),
            totalCountBuy: buyListings.length,
            rentListings: parseAllLiveListingsResponse(rentListings, "RENT"),
            totalCountRent: rentListings.length,
            pgListings: parseAllLiveListingsResponse(pgListings, "PG"),
            totalCountPg: pgListings.length,
          },
        });
      });
  };
};

export const getListingsFromFlatsService = ({
  page = 1,
  pageSize = 20,
  serviceType = "all",
  components = "hits",
  propertyCategoryId = [],
  profileType,
  accountUUID,
  filters,
}) => {
  return instance({
    url: "/sapna/v1/housing/helper/36",
    method: "post",
    withCredentials: true,
    data: {
      page,
      page_size: pageSize,
      profile_type: profileType,
      property_category_id: propertyCategoryId,
      service_type: serviceType,
      components,
      user_flats: {
        final_status: filters,
        profile_uuid: [accountUUID],
      },
      include_payment_status: true,
    },
  });
};

export const getListingDetail = (id, objKeys) => () => {
  return instance({
    url: `${process.env.DOMAINS.CASA}/api/v1/flat/build-cache/detail/${id}`,
    method: "get",
    withCredentials: true,
  }).then(({ data }) => {
    if (objKeys && objKeys.length && Object.keys(data).length) {
      const response = {};
      objKeys.forEach((key) => {
        response[key] = data[key];
      });
      return response;
    } else {
      return data;
    }
  });
};

export const getAllLiveListingsV2 = (filters, accountUUID) => (dispatch) => {
  const {
    activeListingsPayload,
    destructurePropertiesFromFlatsResponse,
    RESIDENTIAL_PROPERTY,
    parseFlatsData,
  } = ownerConstant;
  const payload = {
    filters,
    accountUUID,
    ...activeListingsPayload,
  };
  return getListingsFromFlatsService(payload).then((response) => {
    const residentialProperties = destructurePropertiesFromFlatsResponse(response);

    //fetch polygon hash for each listing
    const listingIds = residentialProperties.map(({ id }) => id);
    const promises = listingIds.reduce(
      (acc, id) => ({
        ...acc,
        [id]: dispatch(getListingDetail(id, ["polygons_hash", "inventory_configs"])),
      }),
      {}
    );
    return resolveObjectOfPromises(promises).then((res) => {
      const properties = residentialProperties.map((ppt) => {
        //merge polygons hash and rest of listing info
        const { id } = ppt;
        return {
          ...ppt,
          ...(res[id] || {}),
        };
      });

      const residentialRentProperties = properties.filter(
        ({
          property_category_id: id,
          user_flats: [{ is_rent: isRent } = {}] = [],
        }) => id === RESIDENTIAL_PROPERTY && isRent
      );
      const residentialBuyProperties = properties.filter(
        ({ property_category_id: id, user_flats: [{ is_buy: isBuy } = {}] = [] }) =>
          id === RESIDENTIAL_PROPERTY && isBuy
      );

      return {
        data: {
          data: {
            [accountUUID]: {
              buy_flat_details: parseFlatsData(residentialBuyProperties),
              rent_flat_details: parseFlatsData(residentialRentProperties),
            },
          },
        },
      };
    });
  });
};

export const getAllLiveListings = (
  accountUUID,
  filters,
  ownerOpportunityFlag = false
) => {
  const url = `/sapna/v1/housing/helper/19`;
  const keys =
    "title,status,price,type,id,inventory_canonical_url,polygons_hash,images_hash,formatted_price,date_added,paid,inventory_configs";
  return (dispatch) =>
    instance({
      url,
      method: "post",
      data: {
        profile_uuids: accountUUID,
        keys,
        filter_status: filters,
      },
    })
      .then(({ data: { data = {} } = {} }) => {
        const {
          buy_flat_details: buyListings = [],
          rent_flat_details: rentListings = [],
          pg_flat_details: pgListings = [],
        } = data?.[accountUUID] || {};
        !ownerOpportunityFlag &&
          dispatch({
            type: ALL_LIVE_LISTINGS,
            payload: {
              buyListings: parseAllLiveListingsResponse(buyListings, "BUY"),
              totalCountBuy: buyListings.length,
              rentListings: parseAllLiveListingsResponse(rentListings, "RENT"),
              totalCountRent: rentListings.length,
              pgListings: parseAllLiveListingsResponse(pgListings, "PG"),
              totalCountPg: pgListings.length,
            },
          });
        ownerOpportunityFlag &&
          dispatch({
            type: NON_RESIDENTIAL_LISTINGS,
            payload: {
              listings: parseAllLiveListingsResponse(pgListings, "PG"),
              listingType: "pg",
            },
          });
        ownerOpportunityFlag &&
          dispatch(
            getLeadsCountForListings({
              buyListings,
              rentListings,
              pgListings,
              accountUUID,
            })
          );
        ownerOpportunityFlag &&
          dispatch(getOwnerListingPaymentData([...buyListings, ...rentListings]));

        return data;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        return err;
      });
};
export const getPendingAndActiveListings = (accountUUID) => {
  return (dispatch) =>
    dispatch(getAllLiveListingsV2(["ACTIVE", "PENDING"], accountUUID))
      .then(({ data: { data = {} } = {} }) => {
        const {
          buy_flat_details: buyListings = [],
          rent_flat_details: rentListings = [],
        } = data?.[accountUUID] || {};
        dispatch({
          type: ALL_AND_LIVE_PENDING_LISTINGS,
          payload: {
            buyListings: parseAllLiveListingsResponse(buyListings, "BUY"),
            rentListings: parseAllLiveListingsResponse(rentListings, "RENT"),
            pgListings: parseAllLiveListingsResponse(rentListings, "PG"),
          },
        });
        dispatch(getOwnerListingPaymentData([...buyListings, ...rentListings]));
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        return err;
      });
};
export const getOwnerListingPaymentData = (listings = []) => {
  return (dispatch, getState) => {
    const listingIds = listings.map(({ id }) => id).join(",");
    if (!listingIds.length) {
      return;
    }
    const url = `/sapna/v1/housing/helper/${
      process.env.NODE_ENV === "production" ? "25" : "28"
    }?listing_ids=${listingIds}`;
    const { ownerListingPaymentInformation } = getState();
    if (ownerListingPaymentInformation) {
      return Promise.resolve();
    }
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const { data: { data: { data = [] } = {} } = {} } = response;
        const payload = data.reduce(
          (
            acc,
            {
              listing_id: id,
              is_listing_payable: isPaidListing,
              is_listing_ad_package_bought: paymentDoneActivationPending,
            }
          ) => {
            acc[id] = {
              isPaidListing,
              paymentDoneActivationPending,
            };
            return acc;
          },
          {}
        );
        dispatch({
          type: OWNER_LISTING_PAYMENT_STATUS,
          payload,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getLiveListings = (
  accountUUID,
  listingType,
  pageSize,
  pageNumber,
  extraParams
) => {
  const url = `${Constants.liveListingsUrls[listingType](
    accountUUID,
    pageSize,
    pageNumber
  )}${extraParams ? `&${Constants.getRequestQueryParamsFromObj(extraParams)}` : ""}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const liveListings =
          Constants.listingTypes[1] === listingType
            ? response.data.data
            : response.data;
        dispatch({
          type: `LIVE_LISTINGS_${listingType}`,
          liveListings: liveListings.hits.map((data) => {
            const {
              title,
              status,
              price,
              type,
              id,
              inventory_canonical_url,
              polygons_hash,
              images_hash,
              formatted_price,
              date_added,
              paid,
            } = data;
            return {
              listingType,
              title,
              status,
              price,
              type,
              id,
              inventory_canonical_url,
              polygons_hash,
              images_hash,
              formatted_price,
              date_added,
              paid,
            };
          }),
          cursor: liveListings.cursor,
          np_total_count: liveListings.np_total_count,
          resale_total_count: liveListings.resale_total_count,
          totalCount: liveListings.total,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getCommercialListings =
  (housingProfileId, statusesArr) => (dispatch) => {
    const {
      commercial: { ACTIVE_LISTING_STATUS: status, listingApi = () => {} },
    } = ownerConstant;
    const url = `/sapna/v1/housing/helper/${listingApi()}?profile_uuid=${housingProfileId}&status=${status}`;
    return instance({
      url,
      method: "get",
    })
      .then(({ data: { data } }) => {
        const {
          data: { hits = [] },
        } = data || {};
        const buy = hits.filter(({ service }) => service === "Resale");
        const rent = hits.filter(({ service }) => service === "Rent");
        const listings = [
          ...parseAllLiveListingsResponse(
            statusesArr?.length
              ? buy.filter(({ status }) => statusesArr.includes(status))
              : buy,
            "BUY",
            "COMMERCIAL"
          ),
          ...parseAllLiveListingsResponse(
            statusesArr?.length
              ? rent.filter(({ status }) => statusesArr.includes(status))
              : rent,
            "RENT",
            "COMMERCIAL"
          ),
        ];
        dispatch({
          type: NON_RESIDENTIAL_LISTINGS,
          payload: {
            listings,
            listingType: "commercial",
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };

export const clearLiveListings = () => {
  return (dispatch) => {
    dispatch({ type: LIVE_LISTINGS_REFRESH });
  };
};

export const clearBoughtPackages = () => {
  return (dispatch) => {
    dispatch({ type: BOUGHT_PACKAGES_REFRESH });
  };
};

export const createTicket = (
  entityDetails,
  description = "<p> Create Ticket </p>",
  entityId,
  entityType,
  category,
  userId,
  summary = false,
  subCategory
) => {
  const url = "/makaanTickets/api/v1/ticket/create";
  return (dispatch) => {
    return instance({
      method: "POST",
      url,
      data: {
        summary: summary || "Please create",
        category: {
          id: category,
        },
        sub_category: {
          id: subCategory || category,
        },
        reporter: {
          id: userId,
        },
        status: {
          id: 1,
        },
        type: {
          id: 1,
        },
        priority: {
          id: 1,
        },
        ticket_reference: {
          entity_type_id: Constants[`${entityType}EntityId`],
          entity_id: entityId,
          link: window.location.href,
        },
        description,
        ticket_attributes: Object.keys(entityDetails).map((field) => ({
          attribute_id: field,
          attribute_value: entityDetails[field],
        })),
      },
    })
      .then((response) => {
        dispatch({
          type: CREATED_TICKET_SUCCESSULLY,
          createdTicketId: response.data.data.id,
          ticketLink: {
            key: response.data.data.key,
            url: response.data.data.site_link,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const resendForVerification = (contactId) => {
  return (dispatch) => {
    if (contactId) {
      const url = `sapna/v1/resendVerificationLink/${contactId}`;
      dispatch({
        type: RESEND_POC_VERIFICATION_IN_PROGRESS,
        payload: true,
        contactId,
      });
      return instance({
        url,
        method: "post",
      })
        .then((response) => {
          const { data } = response.data;
          dispatch({
            type: RESEND_POC_VERIFICATION_SUCCESS,
            payload: data,
            contactId,
          });
        })
        .catch((err) => {
          dispatch({
            type: RESEND_POC_VERIFICATION_ERROR,
            payload: err,
            contactId,
          });
        });
    }
  };
};

export const getBoughtPackages = (housingProfileId, mappingId) => {
  const url = `/sapna/v1/product-activation/get-packages-with-untagged-listings?profileUuid=${housingProfileId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then(({ data: { data } = {} }) => {
        if (data?.length) {
          dispatch({
            type: GET_BOUGHT_PACKAGES,
            payload: data.filter(
              ({ opportunityProductMappingId }) =>
                mappingId === +opportunityProductMappingId
            ),
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const tagPackageToListing = (data, housingProfileId) => (dispatch) => {
  const url = `/sapna/v1/product-activation/tag-product-listings?profileUuid=${housingProfileId}`;
  return instance({
    url,
    method: "post",
    data,
  }).catch((err) => {
    dispatch({
      type: Constants.API_FAIL,
      errMsg: Constants.getErrorMessage(err),
    });
  });
};

export const tagAllPackagesToListings =
  (packageTaggingData, housingProfileId) => (dispatch) => {
    const messageInfo = [];
    const promises = packageTaggingData.map(
      ({ packageName, opportunityProductMappingId, id }) => {
        if (opportunityProductMappingId && id) {
          messageInfo.push({ packageName, id });
          return dispatch(
            tagPackageToListing(
              {
                opportunityProductMappingId: +opportunityProductMappingId,
                listingIds: +id,
              },
              housingProfileId
            )
          );
        }
      }
    );
    return Promise.all(promises).then(() => {
      return messageInfo;
    });
  };

export const getOwnerActivationData =
  (housingProfileId, opportunityProductMappingId) => (dispatch) => {
    return Promise.all([
      dispatch(getAllLiveListings(housingProfileId, "ACTIVE,PENDING")),
      dispatch(getBoughtPackages(housingProfileId, opportunityProductMappingId)),
    ]);
  };

export const setSnackbar = (type, message) => (dispatch) => {
  dispatch({
    type: Constants.SNACKBAR_NOTIFICATION,
    snackBarType: type,
    msg: message,
  });
};

export const getOwnerOpportunityDnd = (accountId) => (dispatch) => {
  const url = `/sapna/v1/account/mark/get-dnd-status?accountId=${accountId}`;
  return instance({
    url,
    method: "get",
  })
    .then((response) => {
      handleOwnerDndResponse({ response, accountId }, dispatch);
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err),
      });
    });
};

export const setOwnerOpportunityDnd = (accountId, dndMarked) => (dispatch) => {
  const url = `/sapna/v1/account/mark/dnd?accountId=${accountId}&dndMarked=${dndMarked}`;
  return instance({
    url,
    method: "post",
  })
    .then(() => {
      dispatch(getOwnerOpportunityDnd(accountId));
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err),
      });
    });
};

export const getOwnerCitiesByPackage = (packageId) => (dispatch, getState) => {
  const expiryDateObj = new Date();
  expiryDateObj.setHours(23, 59, 59, 999);
  return instance({
    url: `/sapna/v1/packages/${packageId}/cities`,
    method: "get",
    cacheExpirytime: expiryDateObj.getTime(),
    cacheable: true,
    cacheKeyBuilder: (url) => url,
  })
    .then(({ data: { data } }) => {
      const { ownerPackageMap } = getState();

      let serviceType, packageType;
      Object.entries(ownerPackageMap).some(([service, packages]) => {
        const packageName = Object.keys(packages).find(
          (key) => packages[key].packageId === packageId
        );
        if (packageName) {
          serviceType = service;
          packageType = packageName;
          return true;
        } else {
          return false;
        }
      });

      if (!(serviceType && packageType)) {
        return;
      }
      dispatch({
        type: OWNER_PACKAGE_CITIES,
        payload: {
          serviceType,
          packageType,
          cities: data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err),
      });
    });
};

const handleOwnerDndResponse = (payload, dispatch) => {
  const { response, accountId } = payload;
  const { data } = response.data;
  dispatch({
    type: OWNER_DND_STATUS,
    payload: {
      value: data,
      accountId,
    },
  });
};

/**
 * 
listings count by profile_uuid
for rent
https://search.housing.com//api/v0/rent/search-count?uuid=7a7bc0b4-e8c1-43dd-9118-6405908a327d

for buy
https://search.housing.com//api/v6/buy/search-count?uuid=7a7bc0b4-e8c1-43dd-9118-6405908a327d

<--------------------------------------Listings------------->
FOR RENT 
https://search.housing.com/api/v1/rent/index/filter?results_per_page=20&uuid=4a9cf142-c800-4907-9822-05bd52d3ca23&source=web

FOR BUY
https://search.housing.com//api/v6/buy/index/filter?results_per_page=20&uuid=4a9cf142-c800-4907-9822-05bd52d3ca23&source=web
 
 */
