import {
  GOT_CONTACTS,
  LSQ_MIGRATION_DETAILS,
  ACCOUNT_DETAILS,
  RESET_ACCOUNT_CHILD,
  NEW_CONTACT_CREATED,
  NEW_CONTACT_ERROR,
  NEW_CONTACT_ERROR_RESET,
  ACCOUNT_CHILD_DETAILS,
  ACCOUNT_CHILD_DETAILS_IN_PROGRESS,
  ACCOUNT_CHILD_DETAILS_ERROR,
  CHILD_ACCOUNT_CREATED,
  RESET_CONTACTS,
  CONTACT_UPDATED,
  CONTACT_DELETED,
  ACCOUNT_UPDATED,
  LEGAL_ENTITY_ATTRIBUTES,
  LEGAL_ENTITY_UPDATED,
  LIVE_LISTINGS_REFRESH,
  ACCOUNT_DETAILS_UPDATED,
  LIVE_LISTINGS_BUY,
  LIVE_LISTINGS_RENT,
  ALL_LIVE_LISTINGS,
  RESEND_POC_VERIFICATION_ERROR,
  RESEND_POC_VERIFICATION_SUCCESS,
  RESEND_POC_VERIFICATION_IN_PROGRESS,
  GET_BOUGHT_PACKAGES,
  BOUGHT_PACKAGES_REFRESH,
  NON_RESIDENTIAL_LISTINGS,
  OWNER_LISTING_PAYMENT_STATUS,
  OWNER_DND_STATUS,
  ALL_AND_LIVE_PENDING_LISTINGS,
} from "../actions/account.actions";

const defaultState = {
  boughtPackages: [],
};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case GOT_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      };
    case ACCOUNT_DETAILS:
      return {
        ...state,
        mappedAccount: null,
        account: action.payload,
      };
    case RESET_ACCOUNT_CHILD:
      return {
        ...state,
        account: {
          ...state.account,
          childAccount: null,
        },
        newChild: null,
      };
    case NEW_CONTACT_ERROR:
      return {
        ...state,
        newContactError: action.errMsg,
      };
    case NEW_CONTACT_ERROR_RESET:
      return {
        ...state,
        newContactError: null,
      };
    case NEW_CONTACT_CREATED:
      return {
        ...state,
        newContact: action.payload,
        newContactError: null,
        snackbar: {
          state: true,
          message: "New contact added!",
          type: "success",
        },
      };
    case ACCOUNT_CHILD_DETAILS:
      return {
        //  data for the same account
        ...state,
        account:
          state.account && state.account.accountId === action.payload.accountId
            ? {
                ...state.account,
                childAccount: action.payload.data,
              }
            : {
                //  data for the different account
                accountId: action.payload.accountId,
                childAccount: action.payload.data,
              },
        accountChildDetailsInProgress: false,
      };
    case ACCOUNT_CHILD_DETAILS_IN_PROGRESS:
      return {
        ...state,
        accountChildDetailsInProgress: action.payload,
      };
    case ACCOUNT_CHILD_DETAILS_ERROR:
      return {
        ...state,
        accountChildDetailsError: action.payload,
      };
    case CHILD_ACCOUNT_CREATED:
      return {
        ...state,
        newChild: action.payload || {},
        snackbar: {
          state: true,
          message: "New Legal Entity Created",
          type: "success",
        },
      };
    case RESET_CONTACTS:
      return {
        ...state,
        contacts: null,
        newContact: null,
        newContactError: null,
        contactDeleted: null,
      };
    case CONTACT_UPDATED:
      return {
        ...state,
        newContact: action.payload,
        newContactError: null,
        snackbar: {
          state: true,
          message: "Contact updated",
          type: "success",
        },
      };
    case CONTACT_DELETED:
      return {
        ...state,
        contactDeleted: true,
        snackbar: {
          state: true,
          message: "Contact deleted successfully.",
          type: "success",
        },
      };
    case ACCOUNT_UPDATED:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Account Details Updated!",
          type: "success",
        },
      };

    case LEGAL_ENTITY_ATTRIBUTES:
      return {
        ...state,
        legalEntityAttributes: action.legalEntityAttributes,
      };
    case LEGAL_ENTITY_UPDATED:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Legal Entity Updated",
          type: "success",
        },
      };
    case ALL_LIVE_LISTINGS: {
      const {
        rentListings,
        buyListings,
        pgListings,
        totalCountBuy,
        totalCountRent,
      } = action.payload;
      return {
        ...state,
        liveListings: {
          ...state.liveListings,
          RENT: rentListings,
          totalCountRENT: totalCountRent,
          BUY: buyListings,
          PG: pgListings,
          totalCountBUY: totalCountBuy,
        },
      };
    }
    case ALL_AND_LIVE_PENDING_LISTINGS: {
      const { rentListings, buyListings, pgListings } = action.payload;
      return {
        ...state,
        liveAndPendingListings: {
          ...state.liveAndPendingListings,
          RENT: rentListings,
          BUY: buyListings,
          PG: pgListings,
        },
      };
    }
    case LIVE_LISTINGS_REFRESH:
      return {
        ...state,
        liveListings: {
          BUY: null,
          RENT: null,
        },
      };
    case LIVE_LISTINGS_BUY:
      return {
        ...state,
        liveListings: {
          ...state.liveListings,
          BUY: action.liveListings,
          totalCountBUY: action.totalCount,
          cursor: action.cursor,
          resale_total_count: action.resale_total_count,
          np_total_count: action.np_total_count,
        },
      };
    case LIVE_LISTINGS_RENT:
      return {
        ...state,
        liveListings: {
          ...state.liveListings,
          RENT: action.liveListings,
          totalCountRENT: action.totalCount,
        },
      };
    case ACCOUNT_DETAILS_UPDATED:
      return {
        ...state,
        accountDetailsUpdated: {},
      };
    case RESEND_POC_VERIFICATION_SUCCESS:
      return {
        ...state,
        resendAccountVerificationMap: {
          ...state.resendAccountVerificationMap,
          [action.contactId]: {
            success: action.payload,
            error: null,
            inProgress: false,
          },
        },
      };
    case RESEND_POC_VERIFICATION_IN_PROGRESS:
      return {
        ...state,
        resendAccountVerificationMap: {
          ...state.resendAccountVerificationMap,
          [action.contactId]: {
            success: null,
            error: null,
            inProgress: true,
          },
        },
      };
    case RESEND_POC_VERIFICATION_ERROR:
      return {
        ...state,
        resendAccountVerificationMap: {
          ...state.resendAccountVerificationMap,
          [action.contactId]: {
            success: null,
            error: action.payload,
            inProgress: false,
          },
        },
      };
    case GET_BOUGHT_PACKAGES:
      return {
        ...state,
        boughtPackages: action.payload,
      };
    case BOUGHT_PACKAGES_REFRESH:
      return {
        ...state,
        boughtPackages: [],
      };
    case NON_RESIDENTIAL_LISTINGS: {
      const { listings, listingType } = action.payload;
      return {
        ...state,
        nonResidentialListings: {
          ...state.nonResidentialListings,
          [listingType]: listings,
        },
      };
    }
    case OWNER_LISTING_PAYMENT_STATUS:
      return {
        ...state,
        ownerListingPaymentInformation: action.payload,
      };
    case OWNER_DND_STATUS:
      return {
        ...state,
        ownerAccountDndStatus: action.payload,
      };
    case LSQ_MIGRATION_DETAILS:
      return {
        ...state,
        lsqMigrationDetails: action.payload,
      };
  }
};

export default reducer;
